import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
        opacity: 1, 
        y: 0,
        transition: { duration: 0.6, ease: "easeOut" }
    }
};

const formVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { 
        opacity: 1, 
        scale: 1,
        transition: { duration: 0.5, ease: "easeOut", delay: 0.2 }
    }
};

function Signup() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        userType: 'client',
        phoneNumber: '',
        username: '',
        idNumber: '',
        idImage: null,
        gender: '',
        city: '',
        workLocation: '',
        workStartTime: '',
        workEndTime: '',
        shiftType: 'single',
        specialRequirements: '',
        preferredDriverNationality: '',
        carPreference: '',
        carType: '',
        carPlate: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear specific field error when user starts typing
        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) { // 5MB
                setErrors(prev => ({
                    ...prev,
                    idImage: 'حجم الملف يجب أن يكون أقل من 5 ميجابايت'
                }));
                return;
            }
            if (!file.type.startsWith('image/')) {
                setErrors(prev => ({
                    ...prev,
                    idImage: 'يجب أن يكون الملف صورة'
                }));
                return;
            }
            setFormData(prev => ({
                ...prev,
                idImage: file
            }));
            // مسح الخطأ إذا كان موجوداً
            setErrors(prev => ({
                ...prev,
                idImage: ''
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};

        // اسم المستخدم
        if (!formData.name.trim()) {
            newErrors.name = 'الاسم مطلوب';
        } else if (formData.name.length < 2) {
            newErrors.name = 'يجب أن يكون الاسم مكون من حرفين على الأقل';
        }

        // البريد الإلكتروني
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!formData.email.trim()) {
            newErrors.email = 'البريد الإلكتروني مطلوب';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'البريد الإلكتروني غير صحيح';
        }

        // كلمة المرور
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!formData.password) {
            newErrors.password = 'كلمة المرور مطلوبة';
        } else if (!passwordRegex.test(formData.password)) {
            newErrors.password = 'كلمة المرور يجب أن تحتوي على 8 أحرف، حرف كبير، رقم، وحرف خاص';
        }

        // تأكيد كلمة المرور
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'كلمة المرور غير متطابقة';
        }

        // رقم الهاتف
        const phoneRegex = /^(05\d{8})$/;
        if (!formData.phoneNumber.trim()) {
            newErrors.phoneNumber = 'رقم الهاتف مطلوب';
        } else if (!phoneRegex.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'رقم الهاتف يجب أن يبدأ بـ 05 ويتكون من 10 أرقام';
        }

        // معلومات العمل للراكب
        if (formData.userType === 'client') {
            if (!formData.city.trim()) {
                newErrors.city = 'المدينة مطلوبة';
            }
            if (!formData.workLocation.trim()) {
                newErrors.workLocation = 'موقع العمل مطلوب';
            }
        }

        // معلومات السائق
        if (formData.userType === 'driver') {
            if (!formData.username?.trim()) {
                newErrors.username = 'اسم المستخدم مطلوب';
            }

            if (!formData.idNumber?.trim()) {
                newErrors.idNumber = 'رقم الهوية مطلوب';
            } else if (!/^\d{10}$/.test(formData.idNumber)) {
                newErrors.idNumber = 'رقم الهوية يجب أن يتكون من 10 أرقام';
            }

            if (!formData.idImage && !formData.idImageUrl) {
                newErrors.idImage = 'صورة الهوية مطلوبة';
            }

            if (!formData.carType?.trim()) {
                newErrors.carType = 'نوع السيارة مطلوب';
            }

            if (!formData.carPlate?.trim()) {
                newErrors.carPlate = 'رقم لوحة السيارة مطلوب';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setLoading(true);

        try {
            const formDataToSend = new FormData();
            
            // إضافة جميع الحقول إلى FormData
            Object.keys(formData).forEach(key => {
                if (key === 'idImage' && formData[key]) {
                    formDataToSend.append('idImage', formData[key]);
                } else if (formData[key] !== '') {
                    formDataToSend.append(key, formData[key]);
                }
            });

            const response = await axios.post('https://backend.ts.site.fasthost.cheap/api/register', 
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            // حفظ التوكن في التخزين المحلي
            localStorage.setItem('token', response.data.token);
            
            // توجيه المستخدم إلى لوحة التحكم
            navigate('/dashboard');
        } catch (error) {
            // عرض رسالة الخطأ من الخادم
            setErrors({
                submit: error.response?.data?.error || 'حدث خطأ أثناء التسجيل'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleUserTypeChange = (userType) => {
        setFormData(prevState => ({
            ...prevState,
            userType
        }));
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-white to-emerald-50">
            <Navbar />
            
            {/* Background Pattern */}
            <div className="absolute inset-0 z-0 opacity-50">
                <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/10 to-emerald-600/10" />
                <div className="absolute inset-0 bg-[url('../public/pattern.svg')] opacity-30" />
            </div>

            <motion.div 
                className="container mx-auto px-4 py-8 sm:py-12 relative z-10"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <motion.div 
                    className="max-w-4xl mx-auto bg-white rounded-2xl shadow-xl overflow-hidden"
                    variants={formVariants}
                >
                    <div className="p-6 sm:p-8">
                        <div className="text-center mb-8">
                            <h2 className="text-3xl font-bold text-gray-900 mb-2">إنشاء حساب جديد</h2>
                            <p className="text-gray-600">أهلاً بك في منصة توصيل السعودية</p>
                        </div>

                        {/* نوع المستخدم */}
                        <div className="mb-8">
                            <div className="flex flex-col sm:flex-row justify-center gap-4 mb-6">
                                <motion.button
                                    type="button"
                                    onClick={() => handleUserTypeChange('client')}
                                    className={`flex-1 py-6 px-6 rounded-xl text-lg font-semibold transition-all duration-300 flex flex-col items-center justify-center gap-4
                                        ${formData.userType === 'client' 
                                            ? 'bg-gradient-to-r from-emerald-500 to-emerald-600 text-white shadow-lg shadow-emerald-200 scale-105' 
                                            : 'bg-white text-gray-600 hover:bg-gray-50 border-2 border-gray-100'}`}
                                    whileHover={{ scale: formData.userType === 'client' ? 1.05 : 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <div className={`w-16 h-16 rounded-full flex items-center justify-center text-2xl
                                        ${formData.userType === 'client' 
                                            ? 'bg-white text-emerald-500' 
                                            : 'bg-gray-100 text-gray-600'}`}>
                                        <i className="fas fa-user-tie"></i>
                                    </div>
                                    <span>عميل</span>
                                </motion.button>
                                <motion.button
                                    type="button"
                                    onClick={() => handleUserTypeChange('driver')}
                                    className={`flex-1 py-6 px-6 rounded-xl text-lg font-semibold transition-all duration-300 flex flex-col items-center justify-center gap-4
                                        ${formData.userType === 'driver' 
                                            ? 'bg-gradient-to-r from-emerald-500 to-emerald-600 text-white shadow-lg shadow-emerald-200 scale-105' 
                                            : 'bg-white text-gray-600 hover:bg-gray-50 border-2 border-gray-100'}`}
                                    whileHover={{ scale: formData.userType === 'driver' ? 1.05 : 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                >
                                    <div className={`w-16 h-16 rounded-full flex items-center justify-center text-2xl
                                        ${formData.userType === 'driver' 
                                            ? 'bg-white text-emerald-500' 
                                            : 'bg-gray-100 text-gray-600'}`}>
                                        <i className="fas fa-car"></i>
                                    </div>
                                    <span>سائق</span>
                                </motion.button>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* المعلومات الأساسية */}
                            <div className="bg-white rounded-xl p-6 shadow-sm border border-gray-100">
                                <h3 className="text-xl font-semibold text-gray-800 mb-6 flex items-center gap-3">
                                    <span className="w-10 h-10 rounded-full bg-emerald-100 text-emerald-600 flex items-center justify-center">
                                        <i className="fas fa-user-circle text-xl"></i>
                                    </span>
                                    المعلومات الأساسية
                                </h3>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-gray-700 text-sm font-medium mb-2">الاسم الكامل</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className={`w-full px-4 py-2 rounded-lg border ${errors.name ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                            placeholder="أدخل اسمك الكامل"
                                            dir="rtl"
                                        />
                                        {errors.name && <p className="mt-1 text-sm text-red-500">{errors.name}</p>}
                                    </div>

                                    <div>
                                        <label className="block text-gray-700 text-sm font-medium mb-2">البريد الإلكتروني</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className={`w-full px-4 py-2 rounded-lg border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                            placeholder="example@domain.com"
                                            dir="ltr"
                                        />
                                        {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
                                    </div>

                                    <div>
                                        <label className="block text-gray-700 text-sm font-medium mb-2">كلمة المرور</label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            className={`w-full px-4 py-2 rounded-lg border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                            placeholder="********"
                                        />
                                        {errors.password && <p className="mt-1 text-sm text-red-500">{errors.password}</p>}
                                    </div>

                                    <div>
                                        <label className="block text-gray-700 text-sm font-medium mb-2">تأكيد كلمة المرور</label>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            className={`w-full px-4 py-2 rounded-lg border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                            placeholder="********"
                                        />
                                        {errors.confirmPassword && <p className="mt-1 text-sm text-red-500">{errors.confirmPassword}</p>}
                                    </div>

                                    <div className="col-span-full">
                                        <label className="block text-gray-700 text-sm font-medium mb-2">رقم الجوال</label>
                                        <input
                                            type="tel"
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            className={`w-full px-4 py-2 rounded-lg border ${errors.phoneNumber ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                            placeholder="05xxxxxxxx"
                                            dir="ltr"
                                        />
                                        {errors.phoneNumber && <p className="mt-1 text-sm text-red-500">{errors.phoneNumber}</p>}
                                    </div>
                                </div>
                            </div>

                            {/* معلومات السائق */}
                            {formData.userType === 'driver' && (
                                <motion.div 
                                    className="bg-white rounded-xl p-6 shadow-sm border border-gray-100"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <h3 className="text-xl font-semibold text-gray-800 mb-6 flex items-center gap-3">
                                        <span className="w-10 h-10 rounded-full bg-emerald-100 text-emerald-600 flex items-center justify-center">
                                            <i className="fas fa-id-card text-xl"></i>
                                        </span>
                                        معلومات السائق
                                    </h3>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-gray-700 text-sm font-medium mb-2">اسم المستخدم</label>
                                            <input
                                                type="text"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                                className={`w-full px-4 py-2 rounded-lg border ${errors.username ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                                placeholder="أدخل اسم المستخدم"
                                                dir="rtl"
                                            />
                                            {errors.username && <p className="mt-1 text-sm text-red-500">{errors.username}</p>}
                                        </div>

                                        <div>
                                            <label className="block text-gray-700 text-sm font-medium mb-2">رقم الهوية</label>
                                            <input
                                                type="text"
                                                name="idNumber"
                                                value={formData.idNumber}
                                                onChange={handleChange}
                                                className={`w-full px-4 py-2 rounded-lg border ${errors.idNumber ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                                placeholder="أدخل رقم الهوية"
                                                maxLength="10"
                                                dir="ltr"
                                            />
                                            {errors.idNumber && <p className="mt-1 text-sm text-red-500">{errors.idNumber}</p>}
                                        </div>

                                        <div className="col-span-full">
                                            <label className="block text-gray-700 text-sm font-medium mb-2">صورة الهوية</label>
                                            <div className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-lg transition-all duration-300
                                                ${errors.idImage 
                                                    ? 'border-red-300 bg-red-50' 
                                                    : formData.idImage 
                                                        ? 'border-emerald-300 bg-emerald-50' 
                                                        : 'border-gray-300 hover:border-emerald-300 hover:bg-emerald-50'}`}>
                                                <div className="space-y-2 text-center">
                                                    <motion.div 
                                                        className="mx-auto h-20 w-20 text-gray-400 flex items-center justify-center"
                                                        initial={{ scale: 0.8 }}
                                                        animate={{ scale: 1 }}
                                                        whileHover={{ scale: 1.1 }}
                                                    >
                                                        {formData.idImage ? (
                                                            <div className="relative">
                                                                <i className="fas fa-check-circle text-emerald-500 text-4xl"></i>
                                                                <motion.div 
                                                                    className="absolute inset-0 border-4 border-emerald-500 rounded-full"
                                                                    initial={{ scale: 0.8, opacity: 0 }}
                                                                    animate={{ scale: 1, opacity: 1 }}
                                                                ></motion.div>
                                                            </div>
                                                        ) : (
                                                            <i className="fas fa-id-card text-5xl text-gray-400"></i>
                                                        )}
                                                    </motion.div>
                                                    <div className="flex flex-col gap-2 items-center text-sm text-gray-600">
                                                        <label className="relative cursor-pointer bg-white px-4 py-2 rounded-full font-medium text-emerald-600 hover:text-emerald-500 shadow-sm hover:shadow transition-all">
                                                            <span>اختر صورة الهوية</span>
                                                            <input
                                                                id="idImage"
                                                                name="idImage"
                                                                type="file"
                                                                className="sr-only"
                                                                onChange={handleFileChange}
                                                                accept="image/*"
                                                            />
                                                        </label>
                                                        <p className="text-gray-500">أو اسحب وأفلت الملف هنا</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500 flex items-center justify-center gap-1">
                                                        <i className="fas fa-info-circle"></i>
                                                        يدعم صيغ PNG, JPG حتى 5 ميجابايت
                                                    </p>
                                                    {formData.idImage && (
                                                        <motion.p 
                                                            className="text-sm text-emerald-600 flex items-center justify-center gap-2"
                                                            initial={{ opacity: 0, y: 10 }}
                                                            animate={{ opacity: 1, y: 0 }}
                                                        >
                                                            <i className="fas fa-file-image"></i>
                                                            {formData.idImage.name}
                                                        </motion.p>
                                                    )}
                                                </div>
                                            </div>
                                            {errors.idImage && (
                                                <motion.p 
                                                    className="mt-1 text-sm text-red-500 flex items-center gap-1"
                                                    initial={{ opacity: 0, x: -10 }}
                                                    animate={{ opacity: 1, x: 0 }}
                                                >
                                                    <i className="fas fa-exclamation-circle"></i>
                                                    {errors.idImage}
                                                </motion.p>
                                            )}
                                        </div>

                                        <div>
                                            <label className="block text-gray-700 text-sm font-medium mb-2">نوع السيارة</label>
                                            <input
                                                type="text"
                                                name="carType"
                                                value={formData.carType}
                                                onChange={handleChange}
                                                className={`w-full px-4 py-2 rounded-lg border ${errors.carType ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                                placeholder="مثال: تويوتا كامري 2020"
                                                dir="rtl"
                                            />
                                            {errors.carType && <p className="mt-1 text-sm text-red-500">{errors.carType}</p>}
                                        </div>

                                        <div>
                                            <label className="block text-gray-700 text-sm font-medium mb-2">رقم لوحة السيارة</label>
                                            <input
                                                type="text"
                                                name="carPlate"
                                                value={formData.carPlate}
                                                onChange={handleChange}
                                                className={`w-full px-4 py-2 rounded-lg border ${errors.carPlate ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                                placeholder="أدخل رقم اللوحة"
                                                dir="ltr"
                                            />
                                            {errors.carPlate && <p className="mt-1 text-sm text-red-500">{errors.carPlate}</p>}
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                            {/* معلومات العميل */}
                            {formData.userType === 'client' && (
                                <motion.div 
                                    className="bg-white rounded-xl p-6 shadow-sm border border-gray-100"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <h3 className="text-xl font-semibold text-gray-800 mb-6 flex items-center gap-3">
                                        <span className="w-10 h-10 rounded-full bg-emerald-100 text-emerald-600 flex items-center justify-center">
                                            <i className="fas fa-building"></i>
                                        </span>
                                        معلومات العمل
                                    </h3>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <div>
                                            <label className="block text-gray-700 text-sm font-medium mb-2">المدينة</label>
                                            <select
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                                className={`w-full px-4 py-2 rounded-lg border ${errors.city ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                                dir="rtl"
                                            >
                                                <option value="">اختر المدينة</option>
                                                <option value="riyadh">الرياض</option>
                                                <option value="jeddah">جدة</option>
                                                <option value="dammam">الدمام</option>
                                                <option value="makkah">مكة المكرمة</option>
                                                <option value="madinah">المدينة المنورة</option>
                                            </select>
                                            {errors.city && <p className="mt-1 text-sm text-red-500">{errors.city}</p>}
                                        </div>

                                        <div>
                                            <label className="block text-gray-700 text-sm font-medium mb-2">نوع الدوام</label>
                                            <select
                                                name="shiftType"
                                                value={formData.shiftType}
                                                onChange={handleChange}
                                                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all"
                                                dir="rtl"
                                            >
                                                <option value="morning">دوام صباحي</option>
                                                <option value="evening">دوام مسائي</option>
                                                <option value="both">دوامين</option>
                                            </select>
                                        </div>

                                        <div className="col-span-full">
                                            <label className="block text-gray-700 text-sm font-medium mb-2">موقع العمل</label>
                                            <input
                                                type="text"
                                                name="workLocation"
                                                value={formData.workLocation}
                                                onChange={handleChange}
                                                className={`w-full px-4 py-2 rounded-lg border ${errors.workLocation ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent transition-all`}
                                                placeholder="أدخل عنوان العمل"
                                                dir="rtl"
                                            />
                                            {errors.workLocation && <p className="mt-1 text-sm text-red-500">{errors.workLocation}</p>}
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                            {/* زر التسجيل */}
                            <div className="flex justify-center pt-6">
                                <motion.button
                                    type="submit"
                                    disabled={loading}
                                    className={`w-full sm:w-auto px-8 py-4 rounded-xl text-white font-semibold text-lg transition-all duration-300
                                        ${loading 
                                            ? 'bg-gray-400 cursor-not-allowed' 
                                            : 'bg-gradient-to-r from-emerald-500 to-emerald-600 hover:shadow-lg hover:shadow-emerald-200 transform hover:-translate-y-1'
                                        }`}
                                    whileHover={{ scale: loading ? 1 : 1.02 }}
                                    whileTap={{ scale: loading ? 1 : 0.98 }}
                                >
                                    {loading ? (
                                        <span className="flex items-center justify-center gap-3">
                                            <motion.i 
                                                className="fas fa-circle-notch text-xl"
                                                animate={{ rotate: 360 }}
                                                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                            ></motion.i>
                                            جاري التسجيل...
                                        </span>
                                    ) : (
                                        <span className="flex items-center justify-center gap-3">
                                            <i className="fas fa-user-plus text-xl"></i>
                                            إنشاء حساب
                                        </span>
                                    )}
                                </motion.button>
                            </div>

                            {/* رسالة الخطأ */}
                            {errors.submit && (
                                <div className="text-center text-red-500 mt-4">
                                    {errors.submit}
                                </div>
                            )}
                        </form>
                    </div>
                </motion.div>
            </motion.div>

            <Footer />
        </div>
    );
}

export default Signup;