import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaHome, FaCar, FaUser, FaPlusCircle } from 'react-icons/fa';

function BottomNavigation({ userData }) {
  const location = useLocation();

  // تعريف القائمة داخل المكون
  const menuItems = React.useMemo(() => {
    const baseItems = [
      { id: 'home', icon: FaHome, label: 'الرئيسية', path: '/dashboard' },
      { id: 'profile', icon: FaUser, label: 'الملف الشخصي', path: '/dashboard/profile' },
      { id: 'rides', icon: FaCar, label: 'الرحلات', path: '/dashboard/trips' },
    ];

    // إضافة رابط إنشاء عرض للسائقين فقط
    if (userData?.user_type === 'driver') {
      baseItems.splice(2, 0, {
        id: 'add-trip',
        icon: FaPlusCircle,
        label: 'إضافة عرض',
        path: '/dashboard/add-trip'
      });
    }

    return baseItems;
  }, [userData?.user_type]);

  const getActiveTab = (pathname) => {
    if (pathname === '/dashboard') return 'home';
    
    const path = pathname.split('/').pop();
    const matchingItem = menuItems.find(item => {
      const itemPath = item.path.split('/').pop();
      return path === itemPath || (path === 'profile' && itemPath === 'profile');
    });
    
    return matchingItem?.id || 'home';
  };

  const activeTab = getActiveTab(location.pathname);

  return (
    <motion.div 
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className="fixed bottom-0 right-0 left-0 h-16 bg-white/80 backdrop-blur-xl border-t border-emerald-100 z-20"
    >
      <div className="h-full max-w-lg mx-auto px-4 flex items-center justify-around">
        {menuItems.map((item) => (
          <Link
            key={item.id}
            to={item.path}
            className="flex flex-col items-center justify-center gap-1"
          >
            <motion.div
              whileTap={{ scale: 0.9 }}
              className={`flex flex-col items-center justify-center
                ${activeTab === item.id ? 'text-emerald-600' : 'text-gray-400'}`}
            >
              <item.icon className={`text-xl ${activeTab === item.id ? 'text-emerald-600' : 'text-gray-400'}`} />
              <span className="text-xs font-medium">{item.label}</span>
            </motion.div>
          </Link>
        ))}
      </div>
    </motion.div>
  );
}

export default BottomNavigation;
