import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch('https://backend.ts.site.fasthost.cheap/api/auth/verify-token', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Invalid token');
        }
        setIsLoggedIn(true);
      })
      .catch(() => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        window.location.href = '/login';
      });
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    window.location.href = '/';
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navLinks = isLoggedIn ? (
    <>
      <Link 
        to="/dashboard" 
        className="px-4 py-2.5 rounded-full text-emerald-600 hover:bg-emerald-50 transition-all duration-200 flex items-center gap-2"
        onClick={() => setIsOpen(false)}
      >
        <i className="fas fa-tachometer-alt"></i>
        <span>لوحة التحكم</span>
      </Link>
      <Link 
        to="/profile" 
        className="px-4 py-2.5 rounded-full text-emerald-600 hover:bg-emerald-50 transition-all duration-200 flex items-center gap-2"
        onClick={() => setIsOpen(false)}
      >
        <i className="fas fa-user"></i>
        <span>الملف الشخصي</span>
      </Link>
      <button 
        onClick={() => {
          setIsOpen(false);
          handleLogout();
        }}
        className="px-4 py-2.5 rounded-full text-red-600 hover:bg-red-50 transition-all duration-200 flex items-center gap-2"
      >
        <i className="fas fa-sign-out-alt"></i>
        <span>تسجيل خروج</span>
      </button>
    </>
  ) : (
    <>
      <Link 
        to="/login" 
        className="px-4 py-2.5 rounded-full text-emerald-600 hover:bg-emerald-50 transition-all duration-200 flex items-center gap-2"
        onClick={() => setIsOpen(false)}
      >
        <i className="fas fa-sign-in-alt"></i>
        <span>تسجيل دخول</span>
      </Link>
      <Link 
        to="/register" 
        className="px-6 py-2.5 rounded-full bg-emerald-500 text-white hover:bg-emerald-600 transition-all duration-200 flex items-center gap-2"
        onClick={() => setIsOpen(false)}
      >
        <i className="fas fa-user-plus"></i>
        <span>إنشاء حساب</span>
      </Link>
    </>
  );

  return (
    <motion.nav 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`fixed w-full top-0 z-50 transition-all duration-300 ${
        isScrolled 
          ? 'bg-white/80 backdrop-blur-md shadow-lg' 
          : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          <Link 
            to="/" 
            className="text-2xl font-bold text-emerald-600 hover:text-emerald-700 transition-colors flex items-center gap-2"
          >
            <i className="fas fa-bolt"></i>
            رحلتي
          </Link>

          {/* القائمة للشاشات الكبيرة */}
          <div className="hidden lg:flex items-center gap-4">
            {navLinks}
          </div>

          {/* زر القائمة للشاشات الصغيرة */}
          <button 
            className="lg:hidden text-emerald-600 hover:text-emerald-700 transition-colors"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="القائمة"
          >
            <i className={`fas fa-${isOpen ? 'times' : 'bars'} text-2xl`}></i>
          </button>

          {/* القائمة المنسدلة للشاشات الصغيرة */}
          <AnimatePresence>
            {isOpen && (
              <motion.div 
                className="lg:hidden flex flex-col absolute top-full left-0 right-0 bg-white shadow-lg p-4"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                {navLinks}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.nav>
  );
}

export default Navbar;