import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaSync, FaEye, FaMapMarkerAlt, FaMoneyBillWave } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';
import BottomNavigation from '../components/BottomNavigation';

function Trips() {
    const [trips, setTrips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            if (window.innerWidth > 768) {
                setSidebarOpen(true);
            } else {
                setSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        fetchUserData();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://backend.ts.site.fasthost.cheap/api/user', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setUserData(data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const getRandomTripImage = async (tripId) => {
        try {
            const response = await fetch(`https://backend.ts.site.fasthost.cheap/api/trips/${tripId}/random-image`);
            const data = await response.json();
            if (response.ok) {
                return data.url;
            }
            return '/default-trip.jpg';
        } catch (error) {
            console.error('Error fetching random image:', error);
            return '/default-trip.jpg';
        }
    };

    const fetchTrips = async () => {
        try {
            const response = await fetch(`https://backend.ts.site.fasthost.cheap/api/trips?page=${currentPage}`);
            const data = await response.json();
            
            const tripsWithImages = await Promise.all(
                data.trips.map(async (trip) => {
                    try {
                        const imageResponse = await fetch(`https://backend.ts.site.fasthost.cheap/api/trips/${trip.id}/random-image`);
                        const imageData = await imageResponse.json();
                        return { ...trip, imageUrl: imageData.url };
                    } catch (error) {
                        console.error('Error fetching trip image:', error);
                        return { ...trip, imageUrl: '/default-trip.jpg' };
                    }
                })
            );
            
            setTrips(tripsWithImages);
            setTotalPages(data.pagination.pages);
        } catch (error) {
            setError('حدث خطأ في جلب الرحلات');
            console.error('Error fetching trips:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTrips();
    }, [currentPage]);

    const handleRefresh = () => {
        fetchTrips();
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (error) {
        return (
            <div className="min-h-screen bg-gray-50 py-8 px-4">
                <div className="max-w-7xl mx-auto">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">خطأ! </strong>
                        <span className="block sm:inline">{error}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-white to-emerald-50">
            {/* Animated Background Pattern */}
            <div className="absolute inset-0 z-0">
                <div className="absolute inset-0 bg-[url('../public/pattern.svg')] opacity-10" />
            </div>

            <div className="relative z-10 flex">
                {/* Sidebar for large screens */}
                {!isMobile && (
                    <Sidebar 
                        userData={userData} 
                        sidebarOpen={sidebarOpen} 
                        setSidebarOpen={setSidebarOpen}
                    />
                )}

                {/* Main Content */}
                <div className={`flex-1 ${!isMobile && sidebarOpen ? 'mr-64' : ''}`}>
                    <div className="p-6 sm:p-8">
                        <div className="max-w-7xl mx-auto">
                            {/* Header Section */}
                            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden mb-6">
                                <div className="p-6 flex flex-col sm:flex-row justify-between items-center gap-4">
                                    <h2 className="text-2xl font-bold text-gray-800">الرحلات المتاحة</h2>
                                    <button
                                        onClick={handleRefresh}
                                        className="flex items-center gap-2 px-6 py-2.5 bg-emerald-500 text-white rounded-xl hover:bg-emerald-600 transition-all duration-200 shadow-sm hover:shadow"
                                    >
                                        <FaSync className={loading ? 'animate-spin' : ''} />
                                        تحديث
                                    </button>
                                </div>
                            </div>

                            {/* Trips Grid */}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {loading ? (
                                    [...Array(3)].map((_, index) => (
                                        <div key={index} className="animate-pulse bg-white rounded-2xl p-6">
                                            <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                                            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                                        </div>
                                    ))
                                ) : trips.length === 0 ? (
                                    <div className="col-span-full text-center py-12">
                                        <p className="text-gray-500">لا توجد رحلات متاحة</p>
                                    </div>
                                ) : (
                                    trips.map((trip) => (
                                        <motion.div
                                            key={trip.id}
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            className="bg-white rounded-2xl shadow-sm border border-gray-100 hover:shadow-md transition-all duration-200"
                                        >
                                            <div className="relative h-48 rounded-t-2xl overflow-hidden">
                                                <img 
                                                    src={trip.imageUrl} 
                                                    alt={`صورة رحلة ${trip.id}`}
                                                    className="w-full h-full object-cover"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = '/default-trip.jpg';
                                                    }}
                                                />
                                            </div>
                                            
                                            <div className="p-6">
                                                <div className="flex items-center gap-4 mb-4">
                                                    <div className="flex-1">
                                                        <h3 className="font-semibold text-lg text-gray-800">{trip.driver_name}</h3>
                                                        <p className="text-emerald-600 font-medium flex items-center gap-2">
                                                            <FaMoneyBillWave />
                                                            <span>{trip.price} ريال</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                
                                                <div className="space-y-3 mb-4">
                                                    <div className="flex items-center gap-2 text-gray-600">
                                                        <FaMapMarkerAlt className="text-emerald-500" />
                                                        <span>من: {trip.start_city}</span>
                                                    </div>
                                                    <div className="flex items-center gap-2 text-gray-600">
                                                        <FaMapMarkerAlt className="text-red-500" />
                                                        <span>إلى: {trip.end_city}</span>
                                                    </div>
                                                </div>

                                                <Link 
                                                    to={`/dashboard/trips/${trip.id}`}
                                                    className="block w-full text-center py-2.5 bg-gray-50 text-emerald-600 rounded-xl hover:bg-emerald-50 transition-colors duration-200"
                                                >
                                                    <FaEye className="inline mr-2" />
                                                    عرض التفاصيل
                                                </Link>
                                            </div>
                                        </motion.div>
                                    ))
                                )}
                            </div>

                            {/* Pagination */}
                            {totalPages > 1 && (
                                <div className="mt-6 flex justify-center">
                                    <div className="flex gap-2">
                                        {[...Array(totalPages)].map((_, index) => (
                                            <button
                                                key={index + 1}
                                                onClick={() => handlePageChange(index + 1)}
                                                className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                                                    currentPage === index + 1
                                                        ? 'bg-emerald-500 text-white'
                                                        : 'bg-white text-gray-600 hover:bg-gray-50'
                                                }`}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Navigation for mobile */}
            {isMobile && <BottomNavigation userData={userData} />}
        </div>
    );
}

export default Trips;
