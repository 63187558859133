import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaMoneyBillWave, FaCalendarAlt, FaImages, FaEdit, FaTrash, FaClock, FaUser, FaTruckMoving, FaCity } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Sidebar from '../components/Sidebar';
import BottomNavigation from '../components/BottomNavigation';
import { saudiCities } from '../data/saudiCities';

const AddTrip = () => {
  const [trips, setTrips] = useState([]);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [editingTrip, setEditingTrip] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [startCity, setStartCity] = useState('');
  const [endCity, setEndCity] = useState('');
  const [tripImages, setTripImages] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    fetchUserData();
    fetchDriverTrips();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://backend.ts.site.fasthost.cheap/api/user', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchDriverTrips = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://backend.ts.site.fasthost.cheap/api/driver/trips', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTrips(response.data);
    } catch (error) {
      toast.error(error.response?.data?.error || 'خطأ في جلب العروض');
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    const previews = files.map(file => URL.createObjectURL(file));
    setPreviewImages(previews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!startCity || !endCity) {
      toast.error('الرجاء اختيار مدينتي البداية والنهاية');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('description', description);
      formData.append('price', price);
      formData.append('startDate', startDate.toISOString());
      formData.append('endDate', endDate.toISOString());
      formData.append('startCity', startCity);
      formData.append('endCity', endCity);

      selectedFiles.forEach(file => {
        formData.append('images', file);
      });

      if (editingTrip) {
        await axios.put(`https://backend.ts.site.fasthost.cheap/api/trips/${editingTrip.id}`, 
          {
            description,
            price: parseFloat(price),
            startCity,
            endCity
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        toast.success('تم تحديث العرض بنجاح');
      } else {
        await axios.post('https://backend.ts.site.fasthost.cheap/api/trips', formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        toast.success('تم إضافة العرض بنجاح');
      }

      setDescription('');
      setPrice('');
      setStartDate(new Date());
      setEndDate(new Date());
      setSelectedFiles([]);
      setPreviewImages([]);
      setStartCity('');
      setEndCity('');
      setEditingTrip(null);
      
      fetchDriverTrips();
    } catch (error) {
      toast.error(error.response?.data?.error || 'حدث خطأ أثناء حفظ العرض');
    } finally {
      setLoading(false);
    }
  };

  const startEditing = (trip) => {
    setEditingTrip(trip);
    setDescription(trip.description);
    setPrice(trip.price.toString());
    setStartDate(new Date(trip.start_date));
    setEndDate(new Date(trip.end_date));
    setStartCity(trip.startCity);
    setEndCity(trip.endCity);
  };

  const cancelEditing = () => {
    setEditingTrip(null);
    setDescription('');
    setPrice('');
    setStartDate(new Date());
    setEndDate(new Date());
    setSelectedFiles([]);
    setPreviewImages([]);
    setStartCity('');
    setEndCity('');
  };

  const fetchTripImages = async (tripId) => {
    if (!userData || !userData.token) {
      return [];
    }

    try {
      const response = await axios.get(
        `https://backend.ts.site.fasthost.cheap/api/trips/${tripId}/images`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching trip images:', error);
      return [];
    }
  };

  const fetchRandomImage = async (tripId) => {
    try {
      const response = await axios.get(`https://backend.ts.site.fasthost.cheap/api/trips/${tripId}/random-image`, {
        headers: {
          'Authorization': `Bearer ${userData.token}`
        }
      });
      setTripImages(prev => ({
        ...prev,
        [tripId]: response.data.url
      }));
    } catch (error) {
      console.error('Error fetching random image:', error);
    }
  };

  useEffect(() => {
    const loadTripData = async () => {
      if (editingTrip && userData && userData.token) {
        try {
          setLoading(true);
          const [tripResponse, imagesResponse] = await Promise.all([
            axios.get(`https://backend.ts.site.fasthost.cheap/api/trips/${editingTrip.id}`, {
              headers: { Authorization: `Bearer ${userData.token}` }
            }),
            fetchTripImages(editingTrip.id)
          ]);

          const trip = tripResponse.data;
          setDescription(trip.description);
          setPrice(trip.price.toString());
          setStartDate(new Date(trip.start_date));
          setEndDate(new Date(trip.end_date));
          setStartCity(trip.start_city);
          setEndCity(trip.end_city);
          
          // تحديث الصور
          if (imagesResponse && imagesResponse.length > 0) {
            setSelectedFiles(imagesResponse.map(img => img.name));
            setPreviewImages(imagesResponse.map(img => img.url));
          }
        } catch (error) {
          console.error('Error loading trip:', error);
          toast.error('حدث خطأ أثناء تحميل بيانات العرض');
        } finally {
          setLoading(false);
        }
      }
    };

    loadTripData();
  }, [editingTrip, userData]);

  useEffect(() => {
    if (trips.length > 0) {
      trips.forEach(trip => {
        if (trip.images && trip.images.length > 0) {
          fetchRandomImage(trip.id);
        }
      });
    }
  }, [trips]);

  const renderSelectedImages = () => {
    return selectedFiles.map((file, index) => (
      <div key={index} className="relative">
        <img
          src={typeof file === 'string' 
            ? `https://backend.ts.site.fasthost.cheap/api/image/${file}`
            : URL.createObjectURL(file)}
          alt={`صورة ${index + 1}`}
          className="w-24 h-24 object-cover rounded-lg shadow-md"
        />
        <button
          type="button"
          onClick={() => {
            const newFiles = [...selectedFiles];
            const newPreviews = [...previewImages];
            newFiles.splice(index, 1);
            newPreviews.splice(index, 1);
            setSelectedFiles(newFiles);
            setPreviewImages(newPreviews);
          }}
          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-white to-emerald-50">
      {/* Animated Background Pattern */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-[url('../public/pattern.svg')] opacity-10" />
        <motion.div 
          className="absolute inset-0"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
            backgroundSize: ['100% 100%', '120% 120%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
      </div>

      <div className="hidden md:block">
        <Sidebar userData={userData} />
      </div>

      <div className="p-4 sm:mr-64 relative z-10">
        <div className="container mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-4 sm:p-6 lg:p-8 mb-8">
              <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6 sm:mb-8">
                <div>
                  <h2 className="text-2xl sm:text-3xl font-bold text-emerald-800 flex items-center">
                    <FaTruckMoving className="ml-3 text-emerald-600" />
                    {editingTrip ? 'تعديل العرض' : 'إضافة عرض جديد'}
                  </h2>
                  <p className="mt-2 text-emerald-600 text-sm sm:text-base">
                    {editingTrip ? 'قم بتحديث تفاصيل العرض' : 'أضف عرضاً جديداً للتوصيل'}
                  </p>
                </div>
                <div className="mt-3 sm:mt-0 text-sm bg-emerald-100 text-emerald-600 py-1 px-3 rounded-full self-start sm:self-center">
                  {trips.length} من 3 عروض
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                  <div className="col-span-full">
                    <label className="block text-emerald-700 text-base sm:text-lg font-medium mb-2 sm:mb-3 flex items-center">
                      <FaMapMarkerAlt className="ml-2 text-emerald-600" />
                      تفاصيل الرحلة
                    </label>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className="w-full p-3 sm:p-4 border-2 border-emerald-200 rounded-lg focus:ring-2 focus:ring-emerald-400 focus:border-transparent transition-all text-emerald-700 bg-white/50"
                      rows="4"
                      required
                      placeholder="اكتب تفاصيل رحلتك هنا..."
                    />
                  </div>

                  <div>
                    <label className="block text-emerald-700 text-base sm:text-lg font-medium mb-2 sm:mb-3 flex items-center">
                      <FaCity className="ml-2 text-emerald-600" />
                      مدينة البداية
                    </label>
                    <select
                      value={startCity}
                      onChange={(e) => setStartCity(e.target.value)}
                      className="w-full p-3 sm:p-4 border-2 border-emerald-200 rounded-lg focus:ring-2 focus:ring-emerald-400 focus:border-transparent transition-all text-emerald-700 bg-white/50"
                      required
                    >
                      <option value="">اختر مدينة البداية</option>
                      {saudiCities.map((city) => (
                        <option key={city} value={city}>{city}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-emerald-700 text-base sm:text-lg font-medium mb-2 sm:mb-3 flex items-center">
                      <FaCity className="ml-2 text-emerald-600" />
                      مدينة الوصول
                    </label>
                    <select
                      value={endCity}
                      onChange={(e) => setEndCity(e.target.value)}
                      className="w-full p-3 sm:p-4 border-2 border-emerald-200 rounded-lg focus:ring-2 focus:ring-emerald-400 focus:border-transparent transition-all text-emerald-700 bg-white/50"
                      required
                    >
                      <option value="">اختر مدينة الوصول</option>
                      {saudiCities.map((city) => (
                        <option key={city} value={city}>{city}</option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-emerald-700 text-base sm:text-lg font-medium mb-2 sm:mb-3 flex items-center">
                      <FaMoneyBillWave className="ml-2 text-emerald-600" />
                      السعر
                    </label>
                    <div className="relative">
                      <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="w-full p-3 sm:p-4 border-2 border-emerald-200 rounded-lg focus:ring-2 focus:ring-emerald-400 focus:border-transparent transition-all text-emerald-700 bg-white/50"
                        required
                        placeholder="0.00"
                      />
                      <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-emerald-600 font-medium">
                        ريال
                      </span>
                    </div>
                  </div>

                  {!editingTrip && (
                    <>
                      <div>
                        <label className="block text-emerald-700 text-base sm:text-lg font-medium mb-2 sm:mb-3 flex items-center">
                          <FaCalendarAlt className="ml-2 text-emerald-600" />
                          تاريخ البداية
                        </label>
                        <DatePicker
                          selected={startDate}
                          onChange={setStartDate}
                          className="w-full p-3 sm:p-4 border-2 border-emerald-200 rounded-lg focus:ring-2 focus:ring-emerald-400 focus:border-transparent transition-all text-emerald-700 bg-white/50"
                          dateFormat="yyyy/MM/dd"
                        />
                      </div>

                      <div>
                        <label className="block text-emerald-700 text-base sm:text-lg font-medium mb-2 sm:mb-3 flex items-center">
                          <FaClock className="ml-2 text-emerald-600" />
                          تاريخ النهاية
                        </label>
                        <DatePicker
                          selected={endDate}
                          onChange={setEndDate}
                          className="w-full p-3 sm:p-4 border-2 border-emerald-200 rounded-lg focus:ring-2 focus:ring-emerald-400 focus:border-transparent transition-all text-emerald-700 bg-white/50"
                          dateFormat="yyyy/MM/dd"
                        />
                      </div>

                      <div className="col-span-full">
                        <label className="block text-emerald-700 text-base sm:text-lg font-medium mb-2 sm:mb-3 flex items-center">
                          <FaImages className="ml-2 text-emerald-600" />
                          صور الرحلة
                        </label>
                        <div className="flex items-center justify-center w-full">
                          <label className="w-full flex flex-col items-center px-4 sm:px-6 py-6 sm:py-8 bg-white/50 rounded-lg border-2 border-dashed border-emerald-300 cursor-pointer hover:bg-emerald-50/50 transition-all">
                            <FaImages className="text-4xl sm:text-5xl text-emerald-500 mb-3" />
                            <span className="text-base sm:text-lg text-emerald-600 mb-1">اختر صور الرحلة</span>
                            <span className="text-sm text-emerald-500">اضغط هنا لاختيار الصور</span>
                            <input
                              type="file"
                              multiple
                              onChange={handleImageChange}
                              className="hidden"
                              accept="image/*"
                            />
                          </label>
                        </div>
                      </div>

                      {previewImages.length > 0 && (
                        <div className="col-span-full">
                          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                            {renderSelectedImages()}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="flex flex-col sm:flex-row justify-center gap-4 pt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full sm:w-auto px-6 py-3 bg-emerald-600 text-white rounded-lg text-base sm:text-lg font-medium hover:bg-emerald-700 transform hover:scale-105 transition-all disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 flex items-center justify-center"
                  >
                    {loading ? (
                      <>
                        <span className="animate-spin ml-2">⌛</span>
                        جاري الحفظ...
                      </>
                    ) : (
                      <>
                        {editingTrip ? (
                          <>
                            <FaEdit className="ml-2" />
                            تحديث العرض
                          </>
                        ) : (
                          <>
                            <FaTruckMoving className="ml-2" />
                            إضافة العرض
                          </>
                        )}
                      </>
                    )}
                  </button>
                  {editingTrip && (
                    <button
                      type="button"
                      onClick={cancelEditing}
                      className="w-full sm:w-auto px-6 py-3 bg-gray-500 text-white rounded-lg text-base sm:text-lg font-medium hover:bg-gray-600 transform hover:scale-105 transition-all flex items-center justify-center"
                    >
                      <FaTrash className="ml-2" />
                      إلغاء التعديل
                    </button>
                  )}
                </div>
              </form>
            </div>

            <div className="mt-8 sm:mt-12">
              <div className="flex items-center justify-between mb-6 sm:mb-8">
                <h3 className="text-xl sm:text-2xl font-bold text-emerald-800 flex items-center">
                  <FaTruckMoving className="ml-3 text-emerald-600" />
                  العروض الحالية
                </h3>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
                {trips.map((trip) => (
                  <motion.div
                    key={trip.id}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition-all"
                  >
                    {(() => {
                      if (trip.images && trip.images.length > 0) {
                        return (
                          <div className="relative h-48">
                            <img
                              src={tripImages[trip.id] || `https://backend.ts.site.fasthost.cheap/api/image/${trip.images[0]}`}
                              alt="صورة العرض"
                              className="w-full h-full object-cover"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/placeholder-image.jpg';
                              }}
                            />
                            {trip.images.length > 1 && (
                              <div className="absolute bottom-3 left-3 bg-black bg-opacity-60 text-white px-3 py-1 rounded-full text-sm flex items-center">
                                <FaImages className="ml-1" />
                                {trip.images.length - 1}+
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })()}
                    
                    <div className="p-6">
                      <div className="flex justify-between items-start mb-4">
                        <div>
                          <h4 className="text-xl sm:text-2xl font-bold text-emerald-800 mb-2 flex items-center">
                            <FaCalendarAlt className="ml-2 text-emerald-600" />
                            {new Date(trip.created_at).toLocaleDateString('ar-SA')}
                          </h4>
                          <div className="flex items-center text-emerald-600 mb-2">
                            <FaUser className="ml-2 text-emerald-600" />
                            <span>{trip.user_name || 'غير معروف'}</span>
                          </div>
                          <div className="flex items-center text-emerald-600 mb-2">
                            <FaCity className="ml-2 text-emerald-600" />
                            <span>{trip.startCity} ➡️ {trip.endCity}</span>
                          </div>
                          <p className="text-emerald-700 line-clamp-2">{trip.description}</p>
                        </div>
                        <div className="text-xl sm:text-2xl font-bold text-emerald-600 bg-emerald-50 px-4 py-2 rounded-full flex items-center">
                          <FaMoneyBillWave className="ml-2" />
                          {trip.price} ريال
                        </div>
                      </div>

                      <div className="flex justify-end">
                        <button
                          onClick={() => startEditing(trip)}
                          className="flex items-center text-emerald-600 hover:text-emerald-700 bg-emerald-50 px-4 py-2 rounded-full transition-colors"
                        >
                          <FaEdit className="ml-2" />
                          تعديل العرض
                        </button>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="md:hidden">
        <BottomNavigation userData={userData} />
      </div>
    </div>
  );
};

export default AddTrip;
