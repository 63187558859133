import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaUser, FaCar } from 'react-icons/fa';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import BottomNavigation from '../components/BottomNavigation';

function Profile() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: ''
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    fetchUserData();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get('https://backend.ts.site.fasthost.cheap/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      setUserData(response.data);
      setFormData({
        name: response.data.name,
        email: response.data.email,
        phone_number: response.data.phone_number
      });
    } catch (err) {
      setError('حدث خطأ في تحميل البيانات');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // التحقق من وجود تغييرات
    const hasChanges = 
      formData.name !== userData.name ||
      formData.email !== userData.email ||
      formData.phone_number !== userData.phone_number;

    if (!hasChanges) {
      setEditMode(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.put('http://localhost:5000/api/user/update', formData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      setUserData(response.data.user);
      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 3000);
      setEditMode(false);
    } catch (err) {
      setError(err.response?.data?.message || 'حدث خطأ في تحديث البيانات');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-white to-emerald-50">
      {/* Sidebar */}
      <Sidebar 
        userData={userData}
        sidebarOpen={sidebarOpen}
        isMobile={isMobile}
        handleLogout={handleLogout}
      />

      {/* Mobile Bottom Navigation */}
      {isMobile && <BottomNavigation />}

      {/* Main Content */}
      <div className={`relative min-h-screen ${isMobile ? 'pb-20' : ''}`}>
        <main className={`p-6 ${!isMobile && 'mr-64'}`}>
          {/* Header */}
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-800 mb-2">الملف الشخصي</h1>
            <p className="text-gray-600">إدارة معلوماتك الشخصية</p>
          </div>

          {/* Success Message */}
          {updateSuccess && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-6 p-4 bg-emerald-100 text-emerald-700 rounded-xl"
            >
              تم تحديث البيانات بنجاح
            </motion.div>
          )}

          {/* Error Message */}
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-6 p-4 bg-red-100 text-red-700 rounded-xl"
            >
              {error}
            </motion.div>
          )}

          {/* Personal Information Card */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8"
          >
            <div className="bg-gradient-to-br from-white to-emerald-50 rounded-3xl p-8 shadow-2xl border border-emerald-100/50 backdrop-blur-xl relative overflow-hidden">
              <div className="absolute top-0 right-0 w-40 h-40 bg-emerald-100 rounded-full filter blur-3xl opacity-20 -mr-20 -mt-20"></div>
              <div className="absolute bottom-0 left-0 w-40 h-40 bg-emerald-200 rounded-full filter blur-3xl opacity-20 -ml-20 -mb-20"></div>
              
              <div className="relative">
                <div className="flex items-center justify-between mb-8">
                  <h3 className="text-2xl font-bold text-gray-800">المعلومات الشخصية</h3>
                  <div className="w-12 h-12 rounded-2xl bg-emerald-500/10 flex items-center justify-center">
                    <FaUser className="text-2xl text-emerald-500" />
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="space-y-6">
                      <div className="relative group">
                        <label className="text-sm font-medium text-emerald-600 mb-1 block">الاسم</label>
                        {editMode ? (
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="w-full text-lg text-gray-800 bg-white p-3 rounded-xl shadow-sm border border-emerald-100 focus:ring-2 focus:ring-emerald-500 focus:border-transparent outline-none transition-all"
                          />
                        ) : (
                          <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20">
                            {userData?.name}
                          </p>
                        )}
                      </div>
                      <div className="relative group">
                        <label className="text-sm font-medium text-emerald-600 mb-1 block">البريد الإلكتروني</label>
                        {editMode ? (
                          <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="w-full text-lg text-gray-800 bg-white p-3 rounded-xl shadow-sm border border-emerald-100 focus:ring-2 focus:ring-emerald-500 focus:border-transparent outline-none transition-all"
                          />
                        ) : (
                          <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20">
                            {userData?.email}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="space-y-6">
                      <div className="relative group">
                        <label className="text-sm font-medium text-emerald-600 mb-1 block">رقم الجوال</label>
                        {editMode ? (
                          <input
                            type="tel"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleInputChange}
                            className="w-full text-lg text-gray-800 bg-white p-3 rounded-xl shadow-sm border border-emerald-100 focus:ring-2 focus:ring-emerald-500 focus:border-transparent outline-none transition-all"
                          />
                        ) : (
                          <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20">
                            {userData?.phone_number}
                          </p>
                        )}
                      </div>
                      <div className="relative group">
                        <label className="text-sm font-medium text-emerald-600 mb-1 block">نوع المستخدم</label>
                        <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20">
                          {userData?.user_type === 'driver' ? 'سائق' : 'عميل'}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-4 mt-8">
                    {editMode ? (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            setEditMode(false);
                            setFormData({
                              name: userData.name,
                              email: userData.email,
                              phone_number: userData.phone_number
                            });
                          }}
                          className="px-6 py-2 bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-colors"
                        >
                          إلغاء
                        </button>
                        <button
                          type="submit"
                          className="px-6 py-2 bg-emerald-500 text-white rounded-xl hover:bg-emerald-600 transition-colors"
                        >
                          حفظ التغييرات
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setEditMode(true)}
                        className="px-6 py-2 bg-emerald-500 text-white rounded-xl hover:bg-emerald-600 transition-colors"
                      >
                        تعديل المعلومات
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </motion.div>

          {/* Driver Details Card - Only shown for drivers */}
          {userData?.user_type === 'driver' && userData?.driver_details && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <div className="bg-gradient-to-br from-white to-emerald-50 rounded-3xl p-8 shadow-2xl border border-emerald-100/50 backdrop-blur-xl relative overflow-hidden">
                <div className="absolute top-0 right-0 w-40 h-40 bg-emerald-100 rounded-full filter blur-3xl opacity-20 -mr-20 -mt-20"></div>
                <div className="absolute bottom-0 left-0 w-40 h-40 bg-emerald-200 rounded-full filter blur-3xl opacity-20 -ml-20 -mb-20"></div>
                
                <div className="relative">
                  <div className="flex items-center justify-between mb-8">
                    <h3 className="text-2xl font-bold text-gray-800">بيانات السيارة</h3>
                    <div className="w-12 h-12 rounded-2xl bg-emerald-500/10 flex items-center justify-center">
                      <FaCar className="text-2xl text-emerald-500" />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="relative group">
                      <label className="text-sm font-medium text-emerald-600 mb-1 block">نوع السيارة</label>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20">
                        {JSON.parse(userData.driver_details).car_type}
                      </p>
                    </div>
                    <div className="relative group">
                      <label className="text-sm font-medium text-emerald-600 mb-1 block">رقم اللوحة</label>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20">
                        {JSON.parse(userData.driver_details).car_plate}
                      </p>
                    </div>
                    <div className="relative group">
                      <label className="text-sm font-medium text-emerald-600 mb-1 block">اسم المستخدم</label>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20">
                        {JSON.parse(userData.driver_details).username}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </main>
      </div>
    </div>
  );
}

export default Profile;
