export const saudiCities = [
  "الرياض",
  "جدة",
  "مكة المكرمة",
  "المدينة المنورة",
  "الدمام",
  "الخبر",
  "تبوك",
  "خميس مشيط",
  "الطائف",
  "نجران",
  "ينبع",
  "القطيف",
  "الجبيل",
  "الخرج",
  "أبها",
  "حائل",
  "الباحة",
  "جازان",
  "سكاكا",
  "القريات",
  "عرعر",
  "بريدة",
  "عنيزة",
  "الرس",
  "الظهران",
  "حفر الباطن",
  "الأحساء",
  "القنفذة",
  "بيشة",
  "الدوادمي"
];
