import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaHome, FaCar, FaUser, FaPlusCircle, FaSignOutAlt } from 'react-icons/fa';

function Sidebar({ userData, sidebarOpen, isMobile, handleLogout }) {
  const location = useLocation();

  // تعريف القائمة داخل المكون
  const menuItems = React.useMemo(() => {
    const baseItems = [
      { id: 'home', icon: FaHome, label: 'الرئيسية', path: '/dashboard' },
      { id: 'profile', icon: FaUser, label: 'الملف الشخصي', path: '/dashboard/profile' },
      { id: 'trips', icon: FaCar, label: 'الرحلات', path: '/dashboard/trips' },
    ];

    // إضافة رابط إنشاء عرض للسائقين فقط
    if (userData?.user_type === 'driver') {
      baseItems.splice(2, 0, {
        id: 'add-trip',
        icon: FaPlusCircle,
        label: 'إضافة عرض',
        path: '/dashboard/add-trip'
      });
    }

    return baseItems;
  }, [userData?.user_type]);

  // تحديد القائمة النشطة
  const activeTab = menuItems.find(item => location.pathname === item.path)?.id || 'home';

  return (
    <AnimatePresence>
      {(!isMobile || sidebarOpen) && (
        <motion.div 
          initial={{ x: 300 }}
          animate={{ x: 0 }}
          exit={{ x: 300 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className={`fixed inset-y-0 right-0 bg-white/80 backdrop-blur-xl shadow-xl z-30
            ${isMobile ? 'w-full md:w-64' : 'w-64'}`}
        >
          {/* Profile Section */}
          <div className="p-6 border-b border-emerald-100">
            <motion.div 
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="relative w-20 h-20 mx-auto mb-4"
            >
              <div className="w-full h-full rounded-full bg-gradient-to-r from-emerald-400 to-emerald-600 flex items-center justify-center text-white text-2xl font-bold">
                {userData?.name?.[0] || 'U'}
              </div>
              <div className="absolute bottom-0 right-0 w-6 h-6 bg-emerald-500 rounded-full border-4 border-white"></div>
            </motion.div>
            <h2 className="text-lg font-bold text-gray-800 text-center mb-1">
              {userData?.name || 'مستخدم'}
            </h2>
            <p className="text-sm text-gray-500 text-center">
              {userData?.email || 'user@example.com'}
            </p>
          </div>

          {/* Menu Items */}
          <nav className="p-4 space-y-2">
            {menuItems.map((item) => (
              <Link
                key={item.id}
                to={item.path}
                className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl text-right transition-all
                  ${activeTab === item.id 
                    ? 'bg-gradient-to-r from-emerald-500 to-emerald-600 text-white shadow-lg shadow-emerald-200' 
                    : 'text-gray-600 hover:bg-emerald-50'}`}
              >
                <item.icon className="text-xl" />
                <span className="font-medium">{item.label}</span>
              </Link>
            ))}
          </nav>

          {/* Logout Button */}
          <div className="absolute bottom-0 right-0 left-0 p-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleLogout}
              className="w-full flex items-center gap-3 px-4 py-3 rounded-xl text-right text-red-600 hover:bg-red-50"
            >
              <FaSignOutAlt className="text-xl" />
              <span className="font-medium">تسجيل الخروج</span>
            </motion.button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Sidebar;
