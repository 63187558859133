import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaUser, FaCar } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';
import BottomNavigation from '../components/BottomNavigation';

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    checkAuth();
    fetchUserData();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await fetch('https://backend.ts.site.fasthost.cheap/api/auth/verify', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        localStorage.removeItem('token');
        navigate('/login');
      }
    } catch (err) {
      console.error('Auth error:', err);
      localStorage.removeItem('token');
      navigate('/login');
    }
  };

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

        const response = await fetch('https://backend.ts.site.fasthost.cheap/api/user', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      setUserData(data);
    } catch (err) {
      setError('حدث خطأ في تحميل البيانات');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-white to-emerald-50">
      {/* Animated Background Pattern */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-[url('../public/pattern.svg')] opacity-10" />
        <motion.div 
          className="absolute inset-0"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
            backgroundSize: ['100% 100%', '120% 120%'],
          }}
          transition={{
            duration: 15,
            repeat: Infinity,
            repeatType: "reverse",
          }}
          style={{
            background: 'radial-gradient(circle at center, rgba(16, 185, 129, 0.1) 0%, transparent 70%)',
          }}
        />
      </div>

      {/* Sidebar */}
      <Sidebar 
        userData={userData}
        sidebarOpen={sidebarOpen}
        isMobile={isMobile}
        handleLogout={handleLogout}
      />

      {/* Main Content */}
      <div className={`relative min-h-screen ${isMobile ? 'pb-20' : ''}`}>
        <main className={`p-6 ${!isMobile && 'mr-64'}`}>
          {/* Header */}
          <div className="mb-8">
            <h1 className="text-2xl font-bold text-gray-800 mb-2">مرحباً {userData?.name || 'مستخدم'}</h1>
            <p className="text-gray-600">مرحباً بك في لوحة التحكم</p>
          </div>

          {/* Statistics Cards - Only shown for drivers */}
          {userData?.user_type === 'driver' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-gradient-to-br from-emerald-500 to-emerald-600 rounded-2xl p-6 text-white shadow-xl"
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-semibold">إجمالي الرحلات</h3>
                  <FaCar className="text-2xl opacity-80" />
                </div>
                <p className="text-3xl font-bold">150</p>
                <p className="text-emerald-100 text-sm mt-2">+12% من الشهر الماضي</p>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-white/80 backdrop-blur-xl rounded-2xl p-6 shadow-xl border border-emerald-100"
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-semibold text-gray-800">التقييم</h3>
                  <div className="flex items-center gap-1 text-emerald-500">
                    {'★'.repeat(5)}
                  </div>
                </div>
                <p className="text-3xl font-bold text-gray-800">4.8</p>
                <p className="text-gray-500 text-sm mt-2">من 200 تقييم</p>
              </motion.div>
            </div>
          )}

          {/* User Details Card */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className="bg-gradient-to-br from-white to-emerald-50 rounded-3xl p-8 shadow-2xl border border-emerald-100/50 backdrop-blur-xl relative overflow-hidden">
              <div className="absolute top-0 right-0 w-40 h-40 bg-emerald-100 rounded-full filter blur-3xl opacity-20 -mr-20 -mt-20"></div>
              <div className="absolute bottom-0 left-0 w-40 h-40 bg-emerald-200 rounded-full filter blur-3xl opacity-20 -ml-20 -mb-20"></div>
              
              <div className="relative">
                <div className="flex items-center justify-between mb-8">
                  <h3 className="text-2xl font-bold text-gray-800">البيانات الشخصية</h3>
                  <div className="w-12 h-12 rounded-2xl bg-emerald-500/10 flex items-center justify-center">
                    <FaUser className="text-2xl text-emerald-500" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="space-y-6">
                    <div className="relative group">
                      <h4 className="text-sm font-medium text-emerald-600 mb-1 transition-colors group-hover:text-emerald-700">الاسم</h4>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20 transition-all group-hover:shadow-md group-hover:bg-white/80">
                        {userData?.name}
                      </p>
                    </div>
                    <div className="relative group">
                      <h4 className="text-sm font-medium text-emerald-600 mb-1 transition-colors group-hover:text-emerald-700">البريد الإلكتروني</h4>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20 transition-all group-hover:shadow-md group-hover:bg-white/80">
                        {userData?.email}
                      </p>
                    </div>
                  </div>
                  <div className="space-y-6">
                    <div className="relative group">
                      <h4 className="text-sm font-medium text-emerald-600 mb-1 transition-colors group-hover:text-emerald-700">رقم الجوال</h4>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20 transition-all group-hover:shadow-md group-hover:bg-white/80">
                        {userData?.phone_number}
                      </p>
                    </div>
                    <div className="relative group">
                      <h4 className="text-sm font-medium text-emerald-600 mb-1 transition-colors group-hover:text-emerald-700">المدينة</h4>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20 transition-all group-hover:shadow-md group-hover:bg-white/80">
                        {userData?.city || 'غير محدد'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Driver Details Card - Only shown for drivers */}
          {userData?.user_type === 'driver' && userData?.driver_details && (
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <div className="bg-gradient-to-br from-white to-emerald-50 rounded-3xl p-8 shadow-2xl border border-emerald-100/50 backdrop-blur-xl relative overflow-hidden">
                <div className="absolute top-0 right-0 w-40 h-40 bg-emerald-100 rounded-full filter blur-3xl opacity-20 -mr-20 -mt-20"></div>
                <div className="absolute bottom-0 left-0 w-40 h-40 bg-emerald-200 rounded-full filter blur-3xl opacity-20 -ml-20 -mb-20"></div>
                
                <div className="relative">
                  <div className="flex items-center justify-between mb-8">
                    <h3 className="text-2xl font-bold text-gray-800">بيانات السيارة</h3>
                    <div className="w-12 h-12 rounded-2xl bg-emerald-500/10 flex items-center justify-center">
                      <FaCar className="text-2xl text-emerald-500" />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="relative group">
                      <h4 className="text-sm font-medium text-emerald-600 mb-1 transition-colors group-hover:text-emerald-700">نوع السيارة</h4>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20 transition-all group-hover:shadow-md group-hover:bg-white/80">
                        {JSON.parse(userData.driver_details).car_type}
                      </p>
                    </div>
                    <div className="relative group">
                      <h4 className="text-sm font-medium text-emerald-600 mb-1 transition-colors group-hover:text-emerald-700">رقم اللوحة</h4>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20 transition-all group-hover:shadow-md group-hover:bg-white/80">
                        {JSON.parse(userData.driver_details).car_plate}
                      </p>
                    </div>
                    <div className="relative group">
                      <h4 className="text-sm font-medium text-emerald-600 mb-1 transition-colors group-hover:text-emerald-700">اسم المستخدم</h4>
                      <p className="text-lg text-gray-800 font-medium bg-white/50 p-3 rounded-xl shadow-sm border border-emerald-100/20 transition-all group-hover:shadow-md group-hover:bg-white/80">
                        {JSON.parse(userData.driver_details).username}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </main>
      </div>

      {/* Mobile Bottom Navigation - Only shown on mobile */}
      {isMobile && <BottomNavigation userData={userData} />}
    </div>
  );
}

export default Dashboard;